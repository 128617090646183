import { Image } from '@/components/ui/Image';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import clipboardIcon from '@images/icons/copyToClipboard.svg';
import styled from 'styled-components';

const TextToCopy = styled.h1`
  font-size: 20px;
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 36px;
  letter-spacing: 6px;
  padding-left: 20px;
`;

const ImageWrapper = styled.div`
  position: relative;
  top: 4px;
  left: 8px;
`;

const COPIED_TEXT = 'クリップボードにコピーしました';

type Props = {
	text: string;
};

export const CopyableText = ({ text }: Props): JSX.Element => {
	const { showSuccessNotification, showErrorNotification } =
		useGlobalNotification();

	const handleCopy = () => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				showSuccessNotification(COPIED_TEXT);
			})
			.catch(() => {
				showErrorNotification('コピーに失敗しました');
			});
	};

	return (
		<Wrapper>
			<TextToCopy>{text}</TextToCopy>
			<Button
				type="button"
				aria-label="クリップボードコピー"
				onClick={handleCopy}
			>
				<ImageWrapper>
					<Image
						src={clipboardIcon}
						width={16}
						height={16}
						alt="clipboard icon"
					/>
				</ImageWrapper>
			</Button>
		</Wrapper>
	);
};
