import { CircularProgress } from '@/components/new/CircularProgress';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import verificationInvalid from '@images/verificationInvalid.svg';
import verificationValid from '@images/verificationValid.svg';
import styled from 'styled-components';

const Heading = styled.h1`
  font-size: 18px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 10px;
`;

const ContentWrapper = styled.div`
  text-align: center;

  p {
    font-size: 16px;
    margin-top: 0;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
`;

const Completed = () => {
	return (
		<>
			<Heading>Slack連携が完了しました</Heading>
			<ImageWrapper>
				<Image
					src={verificationValid}
					width={360}
					height={160}
					aria-label="連携完了"
				/>
			</ImageWrapper>
		</>
	);
};

const HasError = () => {
	return (
		<>
			<Heading>連携に失敗しました</Heading>
			<Text>時間をおいて再度お試しください。</Text>
			<ImageWrapper>
				<Image
					src={verificationInvalid}
					width={360}
					height={160}
					aria-label="連携失敗"
				/>
			</ImageWrapper>
		</>
	);
};

const Loading = () => {
	return (
		<>
			<Heading>連携中です</Heading>
			<Text>処理には最大数分かかることがあります。</Text>
			<ImageWrapper>
				<CircularProgress size="large" />
			</ImageWrapper>
		</>
	);
};

export type ModalContentProps = 'completed' | 'error' | 'loading';

export const ModalContent = ({
	state,
}: {
	state: ModalContentProps;
}): JSX.Element => {
	return (
		<ContentWrapper>
			{state === 'loading' && <Loading />}
			{state === 'error' && <HasError />}
			{state === 'completed' && <Completed />}
		</ContentWrapper>
	);
};
