import { Head } from '@/components/head';
import { Button } from '@/components/new/Button';
import { FlashMessage } from '@/components/new/FlashMessage';
import { Link } from '@/components/new/Link';
import { Image } from '@/components/ui/Image';
import { useGlobalNotification } from '@/hooks/useGlobalNotification';
import logo from '@images/mf_slack.svg';
import jwt from 'jsonwebtoken';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const ButtonStyle = {
	width: '160px',
	marginTop: '5px',
};

const Description = styled.div`
  padding-top: 15px;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;

const SubDescription = styled.div`
  padding: 16px 0 5px;
  text-align: center;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-size: 12px;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
`;

const Line = styled.hr`
  height: 1px;
  border: none;
  background-color: silver;
`;

const InnerWrapper = styled.div`
  background-color: white;
  max-width: 600px;
  width: 100%;
  margin: auto;
  padding: 25px 16px 20px 16px;
`;

const ButtonWrapper = styled.div`
  padding-top: 5px;
`;

const Hero = styled.div`
  text-align: center;
`;

const Box = styled.div`
  text-align: left;
  margin-bottom: 24px;
`;

export const SlackOAuthLogin = (): JSX.Element => {
	const { showErrorNotification } = useGlobalNotification();
	const router = useRouter();
	const { state, error } = router.query;

	const [decodedState, setDecodedState] = useState<string | null>(null);

	useEffect(() => {
		if (!state) {
			showErrorNotification('URL パラメータの状態が指定されていないか無効です');
		}
		// Decode the state (if it's a JWT token or any encoded value)
		const decoded = jwt.decode(state as string);
		console.log(decoded);

		if (decoded && typeof decoded !== 'string' && 'team_domain' in decoded) {
			setDecodedState(decoded?.team_domain || null);
		} else {
			showErrorNotification('状態のデコードに失敗しました');
		}
	}, [state, showErrorNotification]);

	const callbackUrl = `/slack/oauth/callback?state=${state}`;

	return (
		<MainWrapper>
			<InnerWrapper>
				<Head title="" />
				<Hero>
					{/* Show error message from URL if it exists */}
					{error && (
						<Box>
							<FlashMessage color="error">
								{decodeURIComponent(error as string)}
							</FlashMessage>
						</Box>
					)}
					<Image src={logo} />
					<Description>
						{decodedState && (
							<>
								{decodedState}.slack.com のSlackアカウントから
								<br />
								Pay for Businessアカウントに接続します。許可しますか？
							</>
						)}
					</Description>
					<SubDescription>
						Slack上からPay for Businessの機能が使用できるようになります。
					</SubDescription>
					<Line />
					<ButtonWrapper>
						<Link href={callbackUrl}>
							<Button size="large" style={ButtonStyle}>
								許可する
							</Button>
						</Link>
					</ButtonWrapper>
					<ButtonWrapper>
						<Link href="/">
							<Button
								type="button"
								variant="ghost"
								size="large"
								style={ButtonStyle}
							>
								キャンセル
							</Button>
						</Link>
					</ButtonWrapper>
				</Hero>
			</InnerWrapper>
		</MainWrapper>
	);
};
