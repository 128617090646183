export const isEmpty = (str?: string): boolean => !str || str.length === 0;
export const isNotEmpty = (str?: string): boolean => !isEmpty(str);

export const isBlank = (str?: string): boolean => isEmpty(str?.trim());
export const isNotBlank = (str?: string): boolean => !isBlank(str);

export const generateNonceString = (length: number) => {
	const possible =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const randomValues = new Uint8Array(length);
	crypto.getRandomValues(randomValues);

	const text = Array.from(randomValues)
		.map((v) => possible.charAt(v % possible.length))
		.join('');
	
	return text;
};

export const formatIdentificationCode = (
	code: string,
	delimiter = '-',
): string => {
	if (code.length <= 4) return code;
	return `${code.slice(0, 4)}${delimiter}${code.slice(4)}`;
};
