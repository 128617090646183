import { Head } from '@/components/head';
import { PostPaySettingSuspendedReasonEnum, useNewHomeQuery } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { FlashMessage } from '@moneyforward/cloud-react-ui';
import styled from 'styled-components';
import { HighlightAnnouncement } from '../../layouts/HighlightAnnouncement';
import { BannerArea } from './BannerArea';
import { AvailableAmountWidget } from './_components/AvailableAmountWidget';
import { ChargingMoney } from './_components/ChargingMoney';
import { CreditLineWidget } from './_components/CreditLineWidget';
import { PointWidget } from './_components/PointWidget';
import { PostPayGraph } from './_components/PostPayGraph';
import { UnsettledAmountWidget } from './_components/UnsettledAmountWidget';
import { ValueTransactionsWidget } from './_components/ValueTransactionsWidget';
import { WalletBalanceWidget } from './_components/WalletBalanceWidget';

const Wrapper = styled.div`
  max-width: 1240px;
  width: 100%;
  min-width: 940px;
  padding: 20px 20px 80px;
`;

const MessageContainer = styled.div`
  margin-bottom: 20px;
`;

const WidgetsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-template-areas:
    'available-amount available-amount'
    'credit-line unsettle-amount'
    'wallet-balance unsettle-amount'
    'point point';
  grid-gap: 8px;
  margin-bottom: 32px;
`;

const AvailableAmountWidgetArea = styled.div`
  grid-area: available-amount;
`;

const CreditLineWidgetArea = styled.div`
  grid-area: credit-line;
`;

const WalletBalanceWidgetArea = styled.div`
  grid-area: wallet-balance;
`;

const UnsettledAmountWidgetArea = styled.div`
  grid-area: unsettle-amount;
`;

const PointWidgetWidgetArea = styled.div`
  grid-area: point;
`;

const UnderWidgetsContainer = styled.div`
  display: grid;
  gap: 32px;
`;

export const NewHome = (): JSX.Element | null => {
	const { handleQueryError } = useApiError();
	const { data, loading } = useNewHomeQuery({
		onError: handleQueryError,
		fetchPolicy: 'cache-and-network',
	});

	if (loading) return null;

	const isLocked = data?.currentOffice.wallet?.isLocked;
	const lockingMessage =
		data?.currentOffice.wallet?.lockingMessage ??
		'お客さまのウォレットは利用が制限されており、ただいま取引・操作できない状態にあります。詳しくはメールをご確認ください。';

	const suspendedReason =
		data?.currentOffice.wallet?.postPaySetting?.suspendedReason;
	const isDisplaySuspendedReason =
		suspendedReason ===
			PostPaySettingSuspendedReasonEnum.DisconnectedAccounting ||
		suspendedReason === PostPaySettingSuspendedReasonEnum.WithdrawalFailure;
	const suspendedReasonText =
		data?.currentOffice.wallet?.postPaySetting?.suspendedReasonText;

	return (
		<>
			<Head title="ホーム" />
			<ChargingMoney />
			<Wrapper>
				{isDisplaySuspendedReason && suspendedReasonText && (
					<MessageContainer>
						<FlashMessage color="error">{suspendedReasonText}</FlashMessage>
					</MessageContainer>
				)}
				{isLocked && (
					<MessageContainer>
						<FlashMessage color="error">{lockingMessage}</FlashMessage>
					</MessageContainer>
				)}
				<WidgetsGridContainer>
					<AvailableAmountWidgetArea>
						<AvailableAmountWidget />
					</AvailableAmountWidgetArea>
					<CreditLineWidgetArea>
						<CreditLineWidget />
					</CreditLineWidgetArea>
					<WalletBalanceWidgetArea>
						<WalletBalanceWidget />
					</WalletBalanceWidgetArea>
					<UnsettledAmountWidgetArea>
						<UnsettledAmountWidget />
					</UnsettledAmountWidgetArea>
					<PointWidgetWidgetArea>
						<PointWidget />
					</PointWidgetWidgetArea>
				</WidgetsGridContainer>
				<UnderWidgetsContainer>
					<PostPayGraph />
					<BannerArea />
					<ValueTransactionsWidget />
				</UnderWidgetsContainer>
			</Wrapper>
			<HighlightAnnouncement />
		</>
	);
};
