import { CircularProgress } from '@/components/new/CircularProgress';
import { Text } from '@/components/new/Text';
import { Image } from '@/components/ui/Image';
import {
	TenantTypeEnum,
	useCurrentOfficeIsSlackLinkedQuery,
	useSsoLinksQuery,
} from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import queryError from '@images/queryError.svg';
import styled from 'styled-components';
import { ExternalTable } from '../ExternalTable/ExternalTable';
import { MeTable } from '../MeTable/MeTable';
import { MfCloudTable } from '../MfCloudTable/MfCloudTable';

const IntegrationsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const QueryErrorWrapper = styled.div`
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Box = styled.div`
  max-width: 800px;
`;

const QueryError = (): JSX.Element => (
	<QueryErrorWrapper>
		<Image src={queryError} alt="" width={250} height={200} />
		<Text color="notes">読み込みエラー</Text>
	</QueryErrorWrapper>
);

export const IntegrationsContent = (): JSX.Element => {
	const { handleQueryError } = useApiError();
	const {
		data: ssoLinksData,
		loading: ssoLinksLoading,
		error: ssoLinksError,
	} = useSsoLinksQuery({
		onError: handleQueryError,
	});
	const {
		data: slackLinkedData,
		loading: slackLinkedLoading,
		error: slackLinkedError,
	} = useCurrentOfficeIsSlackLinkedQuery({
		onError: handleQueryError,
	});

	const isIndividual =
		ssoLinksData?.currentOffice.tenantType === TenantTypeEnum.Individual;
	const caSsoUrl = ssoLinksData?.currentUser.caSsoUrl;
	const caPlusSsoUrl = ssoLinksData?.currentUser.caPlusSsoUrl;
	const exSsoUrl = ssoLinksData?.currentUser.exSsoUrl;
	const meSsoUrl = ssoLinksData?.currentUser.meSsoUrl;
	const officeSlackLinked = slackLinkedData?.currentOffice.isSlackLinked;

	if (ssoLinksLoading || slackLinkedLoading)
		return <CircularProgress size="large" />;

	if (
		ssoLinksError ||
		slackLinkedError ||
		!caSsoUrl ||
		!exSsoUrl ||
		!meSsoUrl ||
		!caPlusSsoUrl ||
		// officeSlackLinked can be undefined for now
		officeSlackLinked === undefined
	)
		return <QueryError />;

	const slackClientId = process.env.NEXT_PUBLIC_SLACK_CLIENT_ID;
	const redirectUri = `${process.env.NEXT_PUBLIC_CLOUD_WALLET_FRONTEND_URL}/settings/integrations/slack`;
	const slackOauthUrl = encodeURI(
		`https://slack.com/oauth/v2/authorize?scope=chat:write,commands,files:read,im:write,users:read,users:read.email&redirect_uri=${redirectUri}&client_id=${slackClientId}`,
	);

	return (
		<Box>
			<IntegrationsContentWrapper>
				<MfCloudTable
					isIndividual={isIndividual}
					caSsoUrl={caSsoUrl}
					caPlusSsoUrl={caPlusSsoUrl}
					exSsoUrl={exSsoUrl}
				/>
				{isIndividual && <MeTable meSsoUrl={meSsoUrl} />}
				<ExternalTable
					slackOauthUrl={slackOauthUrl}
					officeSlackLinked={officeSlackLinked}
				/>
			</IntegrationsContentWrapper>
		</Box>
	);
};
