import { Head } from '@/components/head';
import { CopyableText } from '@/components/ui/CopyableText';
import { useCreateSlackIntegrationAuthCodeMutation } from '@/graphql';
import { useApiError } from '@/hooks/useApiError';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';

const Description = styled.div`
  padding-top: 1.2em;
  text-align: center;
  line-height: 24px;
  font-weight: 700;

  h3 {
    margin: 0;
  }
`;

const AuthCodeWrapper = styled.div`
  padding-top: 15px;
  text-align: center;
  line-height: 24px;
  font-weight: 700;
  align: center;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
`;

const InnerWrapper = styled.div`
  background-color: white;
  max-width: 600px;
  width: 100%;
  margin: auto;
  padding: 24px 16px 40px 16px;
`;
const FooterDescription = styled.div`
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 13px;
  font-size: 13px;
  line-height: 19.5px;
`;

const Hero = styled.div`
  text-align: center;
`;

export const SlackOAuthCallback = (): JSX.Element => {
	const { handleMutationError } = useApiError();
	const router = useRouter();
	const { state } = router.query;

	const [createSlackIntegrationAuthCode, { data: mutationData }] =
		useCreateSlackIntegrationAuthCodeMutation({
			onCompleted: (data) => {
				if (!data?.createSlackIntegrationAuthCode?.authCode) {
					router.replace('/slack/oauth/login');
				}
			},
			onError: (error) => {
				handleMutationError(error);
				if (state) {
					const errorMessage = encodeURIComponent(
						error.message || 'Something went wrong',
					);
					router.replace(
						`/slack/oauth/login?state=${state}&error=${errorMessage}`,
					);
				}
			},
		});

	// biome-ignore lint/correctness/useExhaustiveDependencies: createSlackIntegrationAuthCode
	useEffect(() => {
		if (state) {
			createSlackIntegrationAuthCode({
				variables: {
					state: state as string,
				},
			});
		}
	}, [state]);

	return (
		<MainWrapper>
			<InnerWrapper>
				<Head title="" />
				<Hero>
					<Description>
						<h3>認証コード</h3>
					</Description>
					<AuthCodeWrapper>
						<CopyableText
							text={
								mutationData?.createSlackIntegrationAuthCode?.authCode ?? ''
							}
						/>
					</AuthCodeWrapper>
					<FooterDescription>
						この認証コードをSlackに入力してください。
					</FooterDescription>
				</Hero>
			</InnerWrapper>
		</MainWrapper>
	);
};
